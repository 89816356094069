.root {
  margin: 0;
  margin-bottom: 30px;
  padding: 0;
  border: none;
  padding: 0 24px;
  
}
.scroll{
  max-height: 400px;
  overflow-y: auto;
}
.scroll ::-webkit-scrollbar {
  width: 12px;
}
.scroll ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: grey;
}

.list {
  margin-right: 12px;
}

.twoColumns {
  @media (--viewportMedium) {
    columns: 2;
  }
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}

@media screen and (--viewportMedium){
  .root{
    padding: 0;
  }
}
.title, .title *{
  margin:0;
  padding: 0;
}
.typeItem{
  margin-bottom: 20px;
}