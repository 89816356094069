.root {
    /* Dimensions */
    width: 100%;
    height: auto;
  
    /* Layout */
    display: flex;
    flex: 1;
    flex-direction: column;
  
    padding-top: 1px;
  
    @media (--viewportMedium) {
      padding-top: 2px;
    }
  }
  
  .formMargins {
    margin-bottom: 24px;
  
    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
  
  .title {
    composes: formMargins;
  }
  
  .textField{
    composes: formMargins;
    flex-shrink: 0;
  }
  
  .error {
    color: var(--failColor);
  }
  
  .submitButton {
    margin-top: auto;
    margin-bottom: 24px;
    flex-shrink: 0;
  
    @media (--viewportLarge) {
      display: inline-block;
      width: 241px;
      margin-top: 100px;
    }
  }
  