.root {
    
    display: flex;
    justify-content: center;
}


/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.input {
  background-color: #f3f3f3;
  text-align:center;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  
  
}

.inputSuccess {
 
}

.inputError {
  
}

.textarea {
}
