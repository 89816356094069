@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root{
    margin-top: 20px;
}
.submitButton{
    margin-top: 40px;
}
.speculateError {
    color: var(--failColor);
    margin: 19px 0 1px 0;
  }
  .inputzone{
    margin-bottom: 20px;
  }
